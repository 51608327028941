import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic8.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic9.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic10.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic11.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic12.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic13.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic14.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic15.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic16.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic17.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/13-july-2/pic18.jpeg",
];

export default function FirstDayFun() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">First Day Fun</h1>

        <p>
          The Pre-Primary and Primary Department of Chinmaya Vidyalaya, New
          Delhi, welcomed the students with a plethora of fun- filled
          activities.
          <br />
          <br />
          The little learners of Pre-Primary enjoyed online activities like
          listening stories, singing and tossing the balloon. They enjoyed
          dancing on the beats of songs.
          <br />
          <br />
          The students of Primary Department showcased their talent in dance,
          singing and storytelling. They also enjoyed Maze activity that helped
          them reach their classrooms. The activity on spin the wheel made them
          share their best memories of holidays.
          <br />
          <br />
          It was a wonderful day where everyone spent beautiful moments of love
          and togetherness .
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
